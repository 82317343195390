@import '../../assets/sass/variables';

.banner-container {
  margin-top: 50px;
  height: calc(100vh - 110px);

  .cont-image {
    width: 300px;
    height: 300px;
    object-fit: cover;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

  }

  .welcome-container {
    padding: 2px;
    border: 1px solid #FAFAFA50;
    width: 250px;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    color: $text-dark ;
    font-size: 14px;
    user-select: none;
  }

  .wrap {
    height: 40px;
    text-shadow: .5px .5px 1px $text-dark;
    font-size: 31px;

  }

  .description {
    color: $text-muted2;
  }

  .name-text {
    font-size: 50px;
  }

  .nav-social-net {
    a {
      i {
        color: #2196f3;
        font-size: 24px;
        transition: .3s all;

        &:hover {
          color: #FAFAFA;
        }
      }


    }
  }
}

@media (max-width:767px) {
  .banner-container {
    height: calc(100vh - 110px);
    margin-top: 0;
  }

  .welcome-container {
    font-size: 16px !important;
  }

  .wrap {
    font-size: 28px !important;
  }

  .name-text {
    font-size: 46px !important;
  }

  .description {
    p {
      font-size: 14px !important;
    }
  }

  .cont-image {
    width: 200px !important;
    height: 200px !important;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}