/* Text */

.text-general {
  color: $text-general;
}

.text-second {
  color: $text-secondary;
}

.text-muted2 {
  color: $text-muted2;
}

.dark .text-general {
  color: $text-dark !important;
}


.f-bold {
  font-weight: bold;
}

.f-light {
  font-weight: lighter;
}

.f-semibold {
  font-weight: 600;
}