.skills {

  .item {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .skill-item {
      width: 160px;
      height: 160px;
      margin-top: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: cornflowerblue;

      .outer {
        height: 160px;
        width: 160px;
        border-radius: 50%;
        padding: 20px;
        box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, .15),
          -6px -6px 10px -1px rgba(0, 0, 0, .7)
      }

      .inner {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, .2),
          inset -4px -4px 6px -1px rgba(0, 0, 0, .7),
          -.5px -.5px 0px rgba(0, 0, 0, 1),
          .5px .5px 0px rgba(0, 0, 0, .15),
          0pc 12px 10px -10px rgba(0, 0, 0, .5)
      }

      circle {
        fill: none;
        stroke: url(#GradientColor);
        stroke-width: 20px;
        stroke-dasharray: 472;
        stroke-dashoffset: 472;
        // animation: anim 2s linear forwards;
      }

      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  // .cont-skills {
  //   padding: 20px;
  //   border-radius: 20px;
  //   box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, .2),
  //     inset -4px -4px 6px -1px rgba(0, 0, 0, .7),
  //     -.5px -.5px 0px rgba(0, 0, 0, 1),
  //     .5px .5px 0px rgba(0, 0, 0, .15),
  //     0pc 12px 10px -10px rgba(0, 0, 0, .5);
  //   background: #333;
  // }
}

@keyframes anim {
  100% {
    stroke-dashoffset: 160;
  }
}

@media (max-width:767px) {
  #skills {}
}