// Estandar: animation: fadeIn .3s linear;
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
}

@keyframes heightAnimation {
	0% {
		height: 0;
	}
}

@keyframes initBanner {

	0% {
		transform: translateY(-100px);
	}
}