@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

// Body Styling
$body-bg: #f8f8fb;
$body-dark: #222222;

$text-general: #003140;
$text-secondary: #2196f3;
$text-dark: #FAFAFAFA;
$text-muted2: #FFFFFF60;