@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

@import "./variables";
@import './class';
@import './base/animations.scss';
@import './base/backgrounds.scss';
@import './base/base.scss';
@import './base/borders.scss';
@import './base/mixins.scss';
@import './base/scrolls.scss';
@import './base/texts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  background: $body-bg;
  transition: .3s ease-in-out;
  overflow: auto;

  &::-webkit-scrollbar-track {
    height: 6px;
    width: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    background-color: #222222;
    width: 6px;
    height: 6px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px $text-secondary;
    background-color: $text-secondary;
    border-radius: 5px;
  }

  &.dark {
    background: $body-dark !important;

  }
}

a {
  text-decoration: none !important;
}

.main-content {
  padding-top: 56px;
  animation: initBanner .5s linear;
}

main {
  overflow: auto;
  min-height: 100vh;
}

@media (max-width:767px) {
  .main-content {
    padding-top: 110px;
  }
}