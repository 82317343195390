@import '../../assets/sass/variables';

header {
  padding: 10px 0;
  justify-content: space-between;
  position: fixed;
  z-index: 9999;
  background: $body-dark;

  .items-nav {
    a {
      cursor: pointer;
      transition: .3s;

      &:hover {
        opacity: .5;
      }
    }
  }


  .buttom-cv {
    // border: 1px solid $text-secondary;
    position: relative;
    display: inline-block;
    text-decoration: none;
    overflow: hidden;
    transform: .2s;
    color: $text-secondary;
    padding: 7px 10px;
    letter-spacing: 1px;
    transition: .5s;
    cursor: pointer;



    &:hover {
      background: $text-secondary;
      color: $text-dark;
      box-shadow: 0 0 1px $text-secondary, 0 0 2px $text-secondary, 0 0 7px $text-secondary;
    }

    span {
      position: absolute;
      display: block;
    }

    span:nth-child(1) {
      top: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, $text-secondary);
    }

    span:nth-child(1) {
      animation: span1 1.5s linear infinite;
      animation-delay: 1s;
    }

    span:nth-child(3) {
      bottom: 0;
      right: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent, $text-secondary);
    }

    span:nth-child(3) {
      animation: span3 1.5s linear infinite;
      animation-delay: 2.2s;
    }

    span:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent, $text-secondary);
    }

    span:nth-child(2) {
      animation: span2 1.5s infinite;
      animation-delay: 1.7s;
    }


    span:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent, $text-secondary);
    }

    span:nth-child(4) {
      animation: span4 1.5s linear infinite;
      animation-delay: 3s;
    }
  }

  .nav-right {
    animation: navRightAnimantion .5s linear;
  }
}


.logo {
  animation: logoAnimation 1s linear;
}

@media (max-width:767px) {
  .cont-logo {
    width: 100%;

    .logo {
      text-align: center;
      width: 100%;
      font-size: 24px !important;
    }
  }

  .nav-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    a {
      font-size: 18px !important;
    }
  }
}


@keyframes navRightAnimantion {
  0% {
    transform: translateX(300px);
  }
}

@keyframes logoAnimation {
  0% {
    transform: translateX(-300px);
  }

  40% {
    transform: translateX(60px);

  }

  60% {
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(30px);

  }
}

@keyframes span1 {
  100% {
    left: 100%;
  }
}

@keyframes span3 {
  100% {
    right: 100%;
  }
}

@keyframes span2 {
  100% {
    top: 100%;
  }
}

@keyframes span4 {
  100% {
    bottom: 100%;
  }
}