.d-middle {
  display: flex;
  align-items: center;
}

.d-middle-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.j-between {
  justify-content: space-between;
}

.j-arounf {
  justify-content: space-around;
}