@import "./borders.scss";
@import "./texts.scss";
@import "./backgrounds.scss";
@import "./scrolls.scss";

body{
	color: var(--grey) !important;
	overflow: hidden;
}

p{ margin: 0 !important; }
a{ text-decoration: none !important; }
.cr-pointer{ cursor:pointer; }
.cr-grabbing{ cursor:grabbing; }
.cr-default { cursor: default !important; }
.tres-puntos{ white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.tres-puntos-2{ overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; }
.w-fit{ width: fit-content }

/* Shadow */
.box-shadow29{
	box-shadow: 0px 1px 3px #00000029;
}

/*opacity*/
.op-0{opacity: 0 !important;}
.op-01{opacity: 0.1 !important;}
.op-02{opacity: 0.2 !important;}
.op-03{opacity: 0.3 !important;}
.op-04{opacity: 0.4 !important;}
.op-05{opacity: 0.5 !important;}
.op-06{opacity: 0.6 !important;}
.op-07{opacity: 0.7 !important;}
.op-08{opacity: 0.8 !important;}
.op-09{opacity: 0.9 !important;}
.op-1{opacity: 1 !important;}

/* Object fit */
.obj-cover{object-fit: cover;}
.obj-contain{object-fit: contain;}

/* Align */
.d-middle-center{ display: flex; align-items: center; justify-content: center; }
.d-middle-column-center{ display: flex; flex-direction: column; align-items: center; justify-content: center; }
.d-middle-end{ display: flex; align-items: center; justify-content: end; }
.d-middle-bt{ display: flex; align-items: center; justify-content: space-between; }
.d-middle-ar{ display: flex; align-items: center; justify-content: space-around; }
.d-middle{ display: flex; align-items: center; }
.j-between{justify-content: space-between;}
.j-around{justify-content: space-around;}
.j-center{justify-content: center;}
.j-end{justify-content: flex-end;}
.a-start{align-items: flex-start;}
.a-center{align-items: center;}
.a-end{align-items: flex-end;}

.fadeIn { animation: fadeIn .3s linear;}

.hover-general{
	&:hover{
		background: var(--blue-state-lightest);
		color: var(--general)
	}
}
.icon-hover-blue{
	&:hover{
		background: var(--blue-state-lightest);
		span.icon svg {
			fill: var(--blue-state);
		}
	}
}

.blue-item{
	&:hover{
		background-color: var(--blue-state-lightest);
		color: var(--blue-state) !important;
	}
}

.ucfirst::first-letter{
	text-transform: uppercase !important;
}
.word-break{
	word-break: break-word;
}

.pl-4{ padding-left: 1.5rem !important; }
.pr-5{ padding-right: 3rem !important; }