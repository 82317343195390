.border-grey{ border: 1px solid var(--grey); }
.border-grey-light{ border: 1px solid var(--grey-light); }
.border-grey-lightest{ border: 1px solid var(--grey-lightest); }

.border-green{ border: 1px solid var(--green-state); }
.border-green-clear{ border: 1px solid var(--green-clear); }
.border-green-light{ border: 1px solid var(--green-state-light); }
.border-green-lightest{ border: 1px solid var(--green-state-lightest); }

.border-blue{ border: 1px solid var(--blue-state); }
.border-blue-light{ border: 1px solid var(--blue-state-light); }
.border-blue-lightest{ border: 1px solid var(--blue-state-lightest); }

.border-orange{ border: 1px solid var(--orange); }

.border-red{ border: 1px solid var(--red-pill); }

.border-whitesmoke{ border: 1px solid var(--whitesmoke); }